import React from 'react';
import styled from 'styled-components';

import DefaultLayout from './DefaultLayout';

const Layout = styled.div`
  position: relative;
  margin: 140px 0 72px;
`;

const BlogLayout = ({ children }) => {
  return (
    <DefaultLayout>
      <Layout>
        <main className="main">{children}</main>
      </Layout>
    </DefaultLayout>
  );
};

export default BlogLayout;
