import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
import Footer from './Footer';

const DefaultLayoutContainer = styled.div``;

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const Layout = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  padding: 12px 10px;

  @media (max-width: 1023px) {
    padding: 12px 40px;
  }
`;

const PurpleText = styled.span`
  color: #7774ff;
  font-weight: bold;
`;

const CornerPlanetContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const CornerPlanet = styled.div`
  position: absolute;
  left: -200px;
  top: -200px;
  width: 400px;
  height: 400px;
  background: #ffe483;
  border-radius: 50%;
`;

const GreenPlanet = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  left: 584px;
  top: 161px;
  background: #c0ff97;
  mix-blend-mode: normal;
  border-radius: 50%;
`;

const YellowPlanet = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  left: 500px;
  top: 716px;
  background: #ffe483;
  mix-blend-mode: normal;
  border-radius: 50%;
`;

const WebSiteName = styled.a`
  position: absolute;
  display: block;
  top: 54px;
  left: 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 97.83%;
  letter-spacing: -0.03em;
  color: #292930;
  text-decoration: none;
  cursor: pointer;
  z-index: 1;

  :hover {
    text-decoration: none;
  }
`;

const DefaultLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query DefaultLayoutQuery {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `);
  const siteName = _.get(data, 'site.siteMetadata.siteName');

  return (
    <DefaultLayoutContainer>
      <Background>
        <CornerPlanetContainer>
          <CornerPlanet />
        </CornerPlanetContainer>
        <GreenPlanet />
        <YellowPlanet />
      </Background>
      <WebSiteName href="/">
        {siteName}
        <PurpleText>.</PurpleText>
      </WebSiteName>
      <Layout>{children}</Layout>
      <Footer />
    </DefaultLayoutContainer>
  );
};

export default DefaultLayout;
