import React from 'react';

import BlogLayout from '../components/BlogLayout';
import SeoHelmet from '../components/SeoHelmet';

const NotFoundPage = ({ location }) => {
  return (
    <BlogLayout location={location}>
      <SeoHelmet title="404: Not Found" location={location} />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </BlogLayout>
  );
};

export default NotFoundPage;
